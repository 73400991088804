import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRole } from "app/shared/hooks";
import { Button, Stack, Tooltip } from "@mui/material";
import ModalHistorico from "./ModalHistorico";
import {
  STATUS_DEFERIDO_PLANO,
  STATUS_EM_ANALISE_PLANO,
  STATUS_INDEFERIDO_PLANO,
  STATUS_INICIADO_PLANO,
  STATUS_NAO_INICIADO_PLANO,
} from "app/shared/constants";
import EnviaAnalise from "./EnviaAnalise";
import FinalizaAnalise from "./FinalizaAnalise";
import { useDispatch } from "react-redux";
import { clearCache } from "app/redux/reducers/cacheSlice";

const ActionHeader = ({ recurso }) => {
  const dispatch = useDispatch();
  const { isEscola } = useRole();
  const [historicoOpen, setHistoricoOpen] = useState(false);
  const navigate = useNavigate();
  const toogleHistorico = () => setHistoricoOpen(!historicoOpen);

  const handleEditarRecurso = () => {
    const planoLabel = recurso.tipo_plano.descricao;
    const parcelaId = recurso.parcela_id;
    const parcelaLabel = recurso.parcela.parcela;
    const planoId = recurso.id;
    navigate(
      `/recurso/form?planoLabel=${planoLabel}&parcelaLabel=${parcelaLabel}&parcelaId=${parcelaId}&id=${planoId}`
    );
  };

  const handleExcucaoRecurso = () => {
    const planoId = recurso.id;
    navigate(`/recurso/execucao?id=${planoId}`);
  };
  const redirectListagem = () => {
    dispatch(clearCache("getParcelasSemPlanoAll"));
    dispatch(clearCache("getParcelasPlanoAtualAll"));
    dispatch(clearCache("getPlanoAll"));
    navigate("/recurso");
  };
  const parcelaStatus = recurso.parcela?.status+"" || "0";
  const parcelaBloqueada = parcelaStatus === "0";
  const listaEnviaAnalise = [STATUS_INDEFERIDO_PLANO,STATUS_INICIADO_PLANO];
  const listaPermiteEdicao = [...listaEnviaAnalise, STATUS_NAO_INICIADO_PLANO];
  const recursoDeferido = recurso.status+"" === STATUS_DEFERIDO_PLANO;
  const testaStatusAndamento = (status) => status+"" === STATUS_EM_ANALISE_PLANO;

  return (
    <>
      <Stack
        direction="row"
        gap={1}
        justifyContent={{ md: "flex-end", xs: "flex-start" }}
        mt={{ xs: "16px", md: 0 }}
      >
        <Button
          onClick={toogleHistorico}
          variant="contained"
          size="small"
          color="secondary"
        >
          Histórico
        </Button>
        {isEscola && recursoDeferido && listaPermiteEdicao.includes(recurso.status_execucao+"") && (
          <Button
            onClick={handleExcucaoRecurso}
            variant="contained"
            size="small"
          >
            Executar
          </Button>
        )}
        {isEscola && listaPermiteEdicao.includes(recurso.status+"") && (
          <Tooltip title={parcelaBloqueada ? "Parcela bloqueada!" : undefined}>
            <Button
              onClick={handleEditarRecurso}
              disabled={parcelaBloqueada}
              variant="contained"
              size="small"
              color="primary"
            >
              Editar
            </Button>
          </Tooltip>
        )}
        {isEscola && listaEnviaAnalise.includes(recurso.status+"") && (
          <EnviaAnalise plano_id={recurso.id} redirect={redirectListagem} />
        )}
        {!isEscola && testaStatusAndamento(recurso.status) && (
          <FinalizaAnalise plano_id={recurso.id} redirect={redirectListagem} />
        )}
        {isEscola && listaEnviaAnalise.includes(recurso.status_execucao+"") && (
          <EnviaAnalise plano_id={recurso.id} redirect={redirectListagem} execucao={1}/>
        )}
        {!isEscola && testaStatusAndamento(recurso.status_execucao) && (
          <FinalizaAnalise plano_id={recurso.id} redirect={redirectListagem} execucao={1}/>
        )}
      </Stack>
      {historicoOpen && (
        <ModalHistorico
          open={historicoOpen}
          onClose={toogleHistorico}
          paramsId={{
            plano_id: recurso.id,
          }}
        />
      )}
    </>
  );
};

export default ActionHeader;
