import { Button, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { ListaVazia, StyledTableContainer } from "app/shared/components";
import { ChipSituacaoItem } from "app/shared/components/ChipSituacao";
import { STATUS_DEFERIDO_ITEM } from "app/shared/constants";
import { formatMoney } from "app/shared/helpers";
import { ModalItemForm } from "pages/recurso/components";
import { useState } from "react";
import { FiEdit2, FiTrash } from "react-icons/fi";

const TableItens = ({ macroAcao, itens, onSave = () => {}, onRemove = () => {} }) => {
  const [modalItem, setModalItem] = useState({ open: false, item: null });

  const getTotalPorItem = (item) => {
    return ((Number(item.custo_capital) + Number(item.custo_custeio)) * item.quantidade);
  };

  const save = (item) => {
    onSave(item, !!modalItem.item);
    setModalItem({ open: false, item: null });
  };

  return (
    <>
      <Button
        sx={{ width: "fit-content", marginBottom: 1 }}
        onClick={() => setModalItem({ open: true, item: null })}
        variant="contained"
        size="small"
      >
        NOVO ITEM
      </Button>
      <StyledTableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>AÇÃO</TableCell>
              <TableCell align="left">CAPITAL (R$)</TableCell>
              <TableCell align="left">CUSTEIO (R$)</TableCell>
              <TableCell align="left">QUANTIDADE</TableCell>
              <TableCell align="left">TOTAL (R$)</TableCell>
              <TableCell align="left" />
            </TableRow>
          </TableHead>
          <TableBody>
            {itens?.map((item, index) => {
              return (
                <TableRow key={index}>
                  <TableCell align="left">{item.acao}</TableCell>
                  <TableCell align="left">
                    {item.custo_capital ? formatMoney(item.custo_capital) : "-"}
                  </TableCell>
                  <TableCell align="left">
                    {item.custo_custeio ? formatMoney(item.custo_custeio) : "-"}
                  </TableCell>
                  <TableCell align="left">{item.quantidade}</TableCell>
                  <TableCell align="left">
                    {formatMoney(getTotalPorItem(item))}
                  </TableCell>
                  <TableCell align="left">
                    {item.situacao.status+"" === STATUS_DEFERIDO_ITEM ? (
                      <ChipSituacaoItem status={item.situacao.status} />
                    ) : (
                      <>
                        <IconButton onClick={() => setModalItem({ open: true, item })}>
                          <FiEdit2 size={20} />
                        </IconButton>
                        <IconButton onClick={() => onRemove(item.id)}>
                          <FiTrash size={20} />
                        </IconButton>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <ListaVazia isListaVazia={!itens?.length} />
      </StyledTableContainer>
      {modalItem.open && (
        <ModalItemForm
          open={modalItem.open}
          item={modalItem.item}
          onClose={() => setModalItem({ open: false, item: null })}
          onSave={save}
          macroAcao={macroAcao}
        />
      )}
    </>
  );
};

export default TableItens;
