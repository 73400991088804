import { MenuItem, Select, Typography } from "@mui/material";
import { buscaItemTipoSituacoes } from "app/services/item-tipo-situacoes";
import { LoaderComponent } from "app/shared/components";
import { useService } from "app/shared/hooks";
import { useEffect } from "react";

const SelectSituacao = ({ item, situacao, setSituacao }) => {
  const { request, response, retry } = useService(
    buscaItemTipoSituacoes,
    false,
    () => {},
    () => {},
    { cache: true, cacheKey: "ITEM_STATUS_ANALISE_KEY" }
  );

  useEffect(() => {
    let params = { limit: 0, visivel: 1 };
    request(params);
  }, []);

  const statusList = response?.data?.filter((status) => status.id+"" !== item.situacao.status+"") || [];

  return (
    <LoaderComponent
      {...response}
      retry={retry}
      message="Carregando situações do item..."
    >
      <Typography mt="10px" variant="button" color="#59829d" mb={1}>
        NOVA SITUAÇÃO DO ITEM*
      </Typography>
      <Select
        size="small"
        displayEmpty
        value={situacao}
        onChange={(e) => setSituacao(e.target.value)}
      >
        <MenuItem value={-1} disabled>
          <em>Selecione uma situação para o item</em>
        </MenuItem>
        {statusList.map((status) => (
          <MenuItem value={status.id} key={`status-${status.id}`}>
            {status.descricao}
          </MenuItem>
        ))}
      </Select>
    </LoaderComponent>
  );
};

export default SelectSituacao;
